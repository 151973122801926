<template>
  <transition appear name="fade">
    <div class="container p-0">
      <appHeader></appHeader>
      <appAdminSidebar></appAdminSidebar>
      <div v-if="isCreationLoading"  class="text-center m-4 bold">
        Creating...
      </div>
      <div v-if="isCreationError" class="text-center m-4 bold">
        Failed to create cards
      </div>
      <div v-if="isCreationSuccessful">
        <div class="card-container px-4">
          <h5>Created {{ totalNewCardsCount }} cards</h5>
          <p><strong>Awarded {{ totalNewCardsValue }} USD</strong></p>
        </div>
        <div class="card-container px-4">
          These users will get a gift-card email
          <div id="cardList">
            <div v-for="(card, index) in createdCards" class="bold mb-2">
              <div class="mr-3 d-inline">{{ card.value }} USD</div><div class="mr-3 d-inline"> {{ card.userName }} </div><div class="mr-3 d-inline"> {{ card.userEmail }}</div>
            </div>
          </div>
        </div>
      </div>
      <appFooter></appFooter>
    </div>
  </transition>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AdminSidebar from '../AdminSidebar.vue'
import { mapState } from 'vuex'

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    appAdminSidebar: AdminSidebar,
  },
  data () {
    return {
      createdCards: []
    }
  },
  computed: {
    ...mapState({
      preparedNewCards: state => state.admin.preparedNewCards,
      chosenTemplateId: state => state.admin.chosenTemplateId,
      currency: state => state.admin.currency,

      isCreationSuccessful: state => state.admin.status.createCards.initialized,
      isCreationLoading: state => state.admin.status.createCards.refreshing,
      isCreationError: state => state.admin.status.createCards.error,
    }),
    totalNewCardsCount: {
      get () {
        return this.createdCards.length
      }
    },
    totalNewCardsValue: {
      get () {
        return this.createdCards.reduce((accumulator, object) => {
          return accumulator + object.value;
        }, 0)
      }
    },
  },
  methods: {
    emptyPreparedNewCards: function () {
      this.$store.dispatch('admin/emptyPreparedNewCards')
    },
    showError: function (message) {
      this.$bvModal.msgBoxOk(message, {
        size: 'sm',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    }
  },
  created () {
    this.$store.dispatch('admin/createCards', {
      cards: this.preparedNewCards,
      currency: this.currency,
      templateId: this.chosenTemplateId,
    })
        .then((resp) => {
              this.createdCards = resp
              this.emptyPreparedNewCards()
            },
            (err) => {
              this.showError('Unknown error')
            })
        .catch(() => {
          this.showError('Unknown error')
        })
  },
}
</script>

<style lang="scss">
</style>
