var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "fade" } }, [
    _c(
      "div",
      { staticClass: "container p-0" },
      [
        _c("appHeader"),
        _c("appAdminSidebar"),
        _vm.isCreationLoading
          ? _c("div", { staticClass: "text-center m-4 bold" }, [
              _vm._v(" Creating... ")
            ])
          : _vm._e(),
        _vm.isCreationError
          ? _c("div", { staticClass: "text-center m-4 bold" }, [
              _vm._v(" Failed to create cards ")
            ])
          : _vm._e(),
        _vm.isCreationSuccessful
          ? _c("div", [
              _c("div", { staticClass: "card-container px-4" }, [
                _c("h5", [
                  _vm._v("Created " + _vm._s(_vm.totalNewCardsCount) + " cards")
                ]),
                _c("p", [
                  _c("strong", [
                    _vm._v("Awarded " + _vm._s(_vm.totalNewCardsValue) + " USD")
                  ])
                ])
              ]),
              _c("div", { staticClass: "card-container px-4" }, [
                _vm._v(" These users will get a gift-card email "),
                _c(
                  "div",
                  { attrs: { id: "cardList" } },
                  _vm._l(_vm.createdCards, function(card, index) {
                    return _c("div", { staticClass: "bold mb-2" }, [
                      _c("div", { staticClass: "mr-3 d-inline" }, [
                        _vm._v(_vm._s(card.value) + " USD")
                      ]),
                      _c("div", { staticClass: "mr-3 d-inline" }, [
                        _vm._v(" " + _vm._s(card.userName) + " ")
                      ]),
                      _c("div", { staticClass: "mr-3 d-inline" }, [
                        _vm._v(" " + _vm._s(card.userEmail))
                      ])
                    ])
                  }),
                  0
                )
              ])
            ])
          : _vm._e(),
        _c("appFooter")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }